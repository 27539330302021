/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";
@import "./theme/font.scss";
@import "./theme/variables.scss";
@import "./theme/style.scss";

// regular style toast
@import "~ngx-toastr/toastr";

//@import "~ngx-sharebuttons/themes/default";
