@charset "UTF-8";

:root {
	--primary: #262626;
	--secondary: #594fbf;
	--white: #ffffff;
	--dark: #000000;
	--light: #f5f5f5;
	--grey: #fafafa;
	--dark-grey: #666666;
}
