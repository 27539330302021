@font-face {
	font-family: OpenSans;
	src: url("./../assets/fonts/OpenSans-Bold.ttf");
	font-weight: bold;
}

@font-face {
	font-family: OpenSans;
	src: url("./../assets/fonts/OpenSans-BoldItalic.ttf");
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: OpenSans;
	src: url("./../assets/fonts/OpenSans-Italic.ttf");
	font-style: italic;
}

@font-face {
	font-family: OpenSans;
	src: url("./../assets/fonts/OpenSans-Light.ttf");
	font-weight: lighter;
}

@font-face {
	font-family: OpenSans;
	src: url("./../assets/fonts/OpenSans-LightItalic.ttf");
	font-weight: lighter;
	font-style: italic;
}

@font-face {
	font-family: OpenSans;
	src: url("./../assets/fonts/OpenSans-Medium.ttf");
	font-weight: normal;
}

@font-face {
	font-family: OpenSans;
	src: url("./../assets/fonts/OpenSans-MediumItalic.ttf");
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: OpenSans;
	src: url("./../assets/fonts/OpenSans-Regular.ttf");
}
